import React from 'react';
import ReturnButton from '../parts/ReturnButton'
import pkg from '../../../package.json'
/* eslint-disable */
import './Content.css'

export default function DefaultContent() {
    return (
        <div className="Content-body">
            <p>
                In order to keep everyone safe, we are screening everyone who enters UHN. in order to keep everyone safe.
                You can help reduce your wait time for screening by using <a href="http://www.uhnpatientscreen.ca">www.uhnpatientscreen.ca</a>.
            </p>
            <p>No personal information of any kind is collected or stored via this process or through <a href="http://www.uhnpatientscreen.ca">www.uhnpatientscreen.ca</a>.</p>
            <p>If you have questions about the online screening tool, please participate in the in-person screening and discuss your concerns with the screener.</p>
            <div className="Privacy-footer">
                <ReturnButton/>
            </div>

            <div style={{textAlign: 'right', fontSize: '80%'}}className="version">
                v{pkg.version}
            </div>
        </div>
    );
}