import React from 'react';
import { useSelector } from 'react-redux';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css'
import './SymptomContent.css'

export default function SymptomContent2(props) {
    const historyLength = useSelector(state => state.reducer.historyLength);
    return (
        <div className="Content-body">
            <span className="Content-question">In the past <b>{historyLength} days</b>, did you have any of these symptoms?</span>
            <ul className="Symptom-list">
                <li>A sore throat</li>
                <li>Difficulty swallowing</li>
                <li>Difficulty breathing</li>
                <li>Shortness of breath</li>
                <li>New or worsening cough</li>
                <li>Nausea/vomiting, diarrhea or stomach pain</li>
                <li>Unexplained fatigue, malaise or muscle aches</li>
            </ul>
            <div className="Content-footer">
                <ButtonFooter {...props} />
                <StatusDots/>
            </div>
        </div>
    );
}