import React from 'react';
import * as actions from '../../redux/actions';
import { useDispatch } from 'react-redux';
/* eslint-disable */
import './Button.css';

function ButtonFooter({ reverse, noFail, action, passScreeningOnNo, passScreeningOnYes, alert }) {
    const dispatch = useDispatch();

    function doAction(answer) {
        if ((answer === 'no' && passScreeningOnNo) || (answer == 'yes' && passScreeningOnYes)) {
            dispatch(actions.screeningPassed);
        } else {
            if (noFail && alert && answer === 'yes') {
                dispatch(actions.goToPage(8));
            } else if (alert && reverse && answer === 'yes') {
                dispatch(actions.screeningAlert);
            } else if (noFail || (answer === 'no' && !reverse) || (answer === 'yes' && reverse)) {
                dispatch(actions.incrementPage);
            } else {
                dispatch(actions.screeningFailed);
            }
        }
        if (action) {
            action(answer);
        }
    }

    return (
        <div className="Button-group">
            <a className="Button" onClick={() => doAction('no')}>
                No
            </a>
            <a className="Button" onClick={() => doAction('yes')}>
                Yes
            </a>
        </div>
    );
}

ButtonFooter.defaultProps = {
    reverse: false,
    noFail: false,
    passScreeningOnNo: false,
    alert: false,
};

export default ButtonFooter;
