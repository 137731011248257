import React from 'react';
import { useDispatch } from 'react-redux';
import { incrementPage } from '../../redux/actions';

import './Button.css'
/* eslint-disable */

export default function BeginButton() {
    const dispatch = useDispatch();

    return (
        <div>
            <a className="Button" onClick={() => dispatch(incrementPage)}>
                Begin
            </a>
        </div>
    );
}