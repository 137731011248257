import React from 'react';
import { useDispatch } from 'react-redux';
import Timestamp from '../../parts/Timestamp';
import ReturnButton from '../../parts/ReturnButton';
import { screeningReset } from '../../../redux/actions';
import './Conclusion.css'

export default function AlertContent() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const timeoutInMinutes = 120
        const timer = setTimeout(() => {
            dispatch(screeningReset);
        }, timeoutInMinutes * 60 * 1000);
        return () => clearTimeout(timer);
    }, [dispatch]);

    return (
        <div className="Conclusion">
            <div className="Content-solo-question">
                <span className="Content-question">
                    Please put on a medical mask and go directly to your clinic. 
                    Show this screen when you check in or speak with a member of your care team. 
                    If you need a mask, you can get one at the hospital entrance. 
                </span>
            </div>

            <Timestamp/>
            <ReturnButton/>
        </div>
    );
}