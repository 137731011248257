import React from 'react';
import { useSelector } from 'react-redux';

import './Timestamp.css'

export default function Timestamp() {
    const time = useSelector(state => state.reducer.submitTime);

    return (
      <div className="Timestamp">
          <span className="Timestamp-date">
              {time.toLocaleDateString('default', { month: 'long', day: 'numeric', year: 'numeric' })}
          </span>
          <span className="Timestamp-time">
              {time.toLocaleTimeString('en-US', { hour12: true, hour: '2-digit', minute: '2-digit' })}
          </span>
      </div>  
    );
}