import React from 'react';
import Timestamp from '../../parts/Timestamp';
import ReturnButton from '../../parts/ReturnButton';
import './Conclusion.css'

export default function FailContent() {
    return (
        <div className="Conclusion">
            <div className="Content-solo-question">
                <span className="Content-question">
                You have <b>not</b> passed entrance screening. Please do <b>not</b> enter the hospital. 
                Thank you for helping to protect UHN patients and staff. 
                </span>
            </div>

            <Timestamp/>
            <ReturnButton/>
        </div>
    );
}