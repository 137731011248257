import React from 'react';
import { useSelector } from 'react-redux';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css'

export default function InfectionContent(props) {
    const historyLength = useSelector(state => state.reducer.historyLength);
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">In the past <b>{historyLength} days</b>, have you tested positive for COVID-19?</span>
            </div>
            <div className="Content-footer">
                <ButtonFooter {...props} />
                <StatusDots/>
            </div>
        </div>
    );
}