import React from 'react';
import { useDispatch } from 'react-redux';
import { setHistoryLength } from '../../redux/actions';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css'

export default function InfectionContent(props) {
    const dispatch = useDispatch();

    function updateHistory(answer) {    
        if (answer == "no") {
            dispatch(setHistoryLength(10));
        } else {
            dispatch(setHistoryLength(20));
        }
    }

    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">Are you receiving cancer treatment or have you had an organ transplant?</span>
            </div>
            <div className="Content-footer">
                <ButtonFooter {...props} action={updateHistory}/>
                <StatusDots/>
            </div>
        </div>
    );
}