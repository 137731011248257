import React from 'react';
import * as actions from '../../redux/actions';
import { useDispatch } from 'react-redux';
import BeginButton from '../parts/BeginButton';

import './Content.css';
/* eslint-disable */

export default function DefaultContent() {
    const dispatch = useDispatch();

    return (
        <div>
            <div className="Content-body-centred">
                <span className="Content-title">UHN Entrance Screening</span>
                <span className="Content-subheading">Please self-screen for COVID-19 before entering the hospital.</span>
            </div>
            <ul className="Content-disclaimer-list">
                <li>Before entering UHN, please answer the following questions on your mobile device.</li>
                <li>You can do this when you arrive or within 2 hours of your appointment time.</li>
            </ul>
            <div className="Content-footer">
                <BeginButton/>
                <div className="Privacy-desc">
                    <span>No personal information of any kind is collected or stored.</span>
                    <a className="Default-link" onClick={() => dispatch(actions.privacyRequest)}>Privacy Notice</a>
                </div>
            </div>
        </div>
    );
}