import produce from 'immer';
import * as actions from './actions';
import { screeningStatus } from '../constants';
import { mapPageToContent } from '../components/content/routes';

const initialState = {
    screeningStatus: screeningStatus.NONE,
    privacyRequest: false,
    submitTime: null,
    currentPage: 0,
    // !: if you add pages, you need to manually update the max page value
    maxPage: mapPageToContent?.length - 1,
    // setting this to 10 for all patients
    historyLength: 10,
};

export default (state = initialState, action) =>
    produce(state, (draft) => {
        const { type, payload } = action;
        switch (type) {
            case actions.SCREENING_FAILED:
                draft.screeningStatus = screeningStatus.FAIL;
                draft.submitTime = new Date();
                break;
            case actions.SCREENING_PASSED:
                draft.screeningStatus = screeningStatus.PASS;
                draft.submitTime = new Date();
                break;
            case actions.SCREENING_ALERT:
                draft.screeningStatus = screeningStatus.ALERT;
                draft.submitTime = new Date();
                break;
            case actions.SCREENING_RESET:
                draft.screeningStatus = screeningStatus.NONE;
                draft.privacyRequest = false;
                draft.submitTime = null;
                draft.currentPage = 0;
                break;
            case actions.PRIVACY_REQUEST:
                draft.privacyRequest = true;
                break;
            case actions.INCREMENT_PAGE:
                draft.currentPage = draft.currentPage + 1;
                if (draft.currentPage > state.maxPage) {
                    draft.screeningStatus = screeningStatus.PASS;
                    draft.submitTime = new Date();
                    draft.currentPage = 0;
                }
                break;
            case actions.GO_TO_PAGE:
                draft.currentPage = Number(payload) || draft.currentPage;
                if (draft.currentPage > state.maxPage) {
                    draft.screeningStatus = screeningStatus.PASS;
                    draft.submitTime = new Date();
                    draft.currentPage = 0;
                }
                break;
            case actions.RESET_PAGE:
                draft.currentPage = 0;
                break;
            case actions.SET_HISTORY_LENGTH:
                draft.historyLength = payload.history;
                break;
            default:
                return draft;
        }
    });
