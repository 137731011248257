import React from 'react';
import { Provider } from 'react-redux'

import store from './redux/store'
import './App.css';
import ContentContainer from './components/containers/ContentContainer';

function App() {
  return (
    <Provider store={store}>
        <ContentContainer/>
    </Provider>
  );
}

export default App;
