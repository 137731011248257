import React from 'react';
import { connect } from 'react-redux';
import { screeningStatus } from '../../constants';
import { mapPageToContent } from '../content/routes';
import PassContent from '../content/conclusion/PassContent';
import FailContent from '../content/conclusion/FailContent';
import AlertContent from '../content/conclusion/AlertContent';
import PrivacyContent from '../content/PrivacyContent';

import logo from '../../images/uhn-logo.png';
import './Container.css';

function ContentContainer({ status, privacy, currentPage }) {
    function content() {
        if (privacy) {
            return <PrivacyContent />;
        } else if (status === screeningStatus.PASS) {
            return <PassContent />;
        } else if (status === screeningStatus.FAIL) {
            return <FailContent />;
        } else if (status === screeningStatus.ALERT) {
            return <AlertContent />;
        } else {
            return mapPageToContent.find((content) => content.key === currentPage)?.page;
        }
    }

    return (
        <div className={`Container-${status}`}>
            <div className="Container-main">
                <header className="Container-header">
                    <img src={logo} className="Container-logo" alt="UHN Logo" />
                </header>
                {content()}
            </div>
        </div>
    );
}

function mapStateToProps(state) {
    return {
        status: state.reducer.screeningStatus,
        privacy: state.reducer.privacyRequest,
        currentPage: state.reducer.currentPage,
    };
}

export default connect(mapStateToProps)(ContentContainer);
