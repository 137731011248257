import React from 'react';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css';
import './SymptomContent.css';

export default function IsolatingContent(props) {
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">
                    In the past <b>10 days</b>, have you been told by a quarantine officer, public health official or physician that you need to isolate
                    (staying at home) due to recent travel or COVID-19 risk?
                </span>

                <div className="Content-footer">
                    <ButtonFooter {...props} />
                    <StatusDots />
                </div>
            </div>
        </div>
    );
}
