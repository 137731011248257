import React from 'react';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css';

export default function VaccinationContent(props) {
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">
                    UHN's vaccination policy remains in effect. Are you fully vaccinated*?
                </span>
            </div>
            <div className="Content-footer">
                <div className="Content-smaller-text">
                    *Fully vaccinated means that you have received 2 or more doses of 
                    a Health Canada approved vaccine. Your 2nd dose must have been at least 14 days ago.
                </div>
                <ButtonFooter {...props} />
                <StatusDots />
            </div>
        </div>
    );
}
