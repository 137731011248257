import React from 'react';
import { useSelector } from 'react-redux';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css'
import './SymptomContent.css'

export default function SymptomContent(props) {
    const historyLength = useSelector(state => state.reducer.historyLength);
    return (
        <div className="Content-body">
            <span className="Content-question">In the past <b>{historyLength} days</b>, did you have any of these symptoms?</span>
            <ul className="Symptom-list">
                <li>A fever</li>
                <li>Chills</li>
                <li>Unexplained headache</li>
                <li>Eye pain or pink eye</li>
                <li>Decrease or loss of sense of taste or smell</li>
                <li>A runny or stuff nose without another cause (such as allergies)</li>
            </ul>
            <div className="Content-footer">
                <ButtonFooter {...props} />
                <StatusDots/>
            </div>
        </div>
    );
}