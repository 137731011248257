import React from 'react';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css'

export default function PatientContent(props) {
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">Are you a patient with an appointment today?</span>
            </div>
            <div className="Content-footer">
                <ButtonFooter {...props} />
                <StatusDots/>
            </div>
        </div>
    );
}