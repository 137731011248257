import React from 'react';
import { useDispatch } from 'react-redux';
import { screeningReset } from '../../redux/actions';
/* eslint-disable */
import './Button.css'

export default function ReturnButton() {
    const dispatch = useDispatch();

    return (
        <div>
            <a className="Button" onClick={() => dispatch(screeningReset)}>
                Return
            </a>
        </div>
    );
}