import React from 'react';
import DefaultContent from '../content/DefaultContent';
import SymptomContent from './SymptomContent';
import ContactContent from './ContactContent';
import CancerContent from './CancerContent';
import InfectionContent from './InfectionContent';
import SymptomContent2 from './SymptomContent2';
import PatientContent from './PatientContent';
import IsolatingContent from './IsolatingContent';
import VaccinationContent from './VaccinationContent';

export const mapPageToContent = [
    {
        key: 0,
        page: <DefaultContent />,
    },
    {
        key: 1,
        page: <CancerContent noFail />,
    },
    {
        key: 2,
        page: <InfectionContent noFail alert />,
    },
    {
        key: 3,
        page: <SymptomContent noFail alert />,
    },
    {
        key: 4,
        page: <SymptomContent2 noFail alert />,
    },
    {
        key: 5,
        page: <ContactContent noFail alert />,
    },
    {
        key: 6,
        page: <IsolatingContent noFail alert />,
    },
    {
        key: 7,
        page: <VaccinationContent noFail alert passScreeningOnYes />,
    },
    {
        key: 8,
        page: <PatientContent alert reverse />,
    }
];