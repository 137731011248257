import React from 'react';
import { useDispatch } from 'react-redux';
import Timestamp from '../../parts/Timestamp';
import ReturnButton from '../../parts/ReturnButton';
import { screeningReset } from '../../../redux/actions';
import './Conclusion.css'

export default function PassContent() {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const timeoutInMinutes = 120
        const timer = setTimeout(() => {
            dispatch(screeningReset);
        }, timeoutInMinutes * 60 * 1000);
        return () => clearTimeout(timer);
    }, [dispatch]);

    return (
        <div className="Conclusion">
            <span className="Pass-followup-text">
                You have passed screening. Please put on a medical mask before entering the hospital. 
                If you need a mask, you can get one at the hospital entrance. 
                Thank you for helping to protect UHN patients and staff.
            </span>
            <Timestamp/>
            <ReturnButton/>
        </div>
    );
}