import React from 'react';
import ButtonFooter from '../parts/ButtonFooter';
import StatusDots from '../parts/StatusDots';
/* eslint-disable */
import './Content.css';

export default function ContactContent(props) {
    return (
        <div className="Content-body">
            <div className="Content-solo-question">
                <span className="Content-question">
                    In the past <b>10 days</b>, were you in close contact with someone who tested positive for COVID-19 or who had a new respiratory illness?
                </span>
            </div>
            <div className="Content-footer">
                <ButtonFooter {...props} />
                <StatusDots />
            </div>
        </div>
    );
}
